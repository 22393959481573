
$(document).ready(function () {
    console.log('hello');
    let tl = gsap.timeline({

    });

    tl.to('#js-underline', {
        left: 0,
        duration: 0.8,
        delay: 0.3,
        ease: "elastic",
    }).to('#js-underline', {
        width: '90%',
        duration: 0.3,
        delay: 0.1,
        ease: "power2.inOut",
    }).to('#js-arrow', {
        opacity: 0.3,
        duration: 0.4,
        delay: 0.1,
    });

    $('#js-arrow').on('click', function () {
        $('#js-arrow').toggleClass('active');
        $('#js-linkswrapper').toggleClass('active');
    });


})
